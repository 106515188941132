@use '../abstracts' as *;

/* Wysiwyg editor
 **************************************** */
.editor-styles-wrapper,
.mce-content-body {
  &::before,
  &::after {
    content: '';
    display: table;
  }
  &::after {
    clear: both;
  } 
}

.editor-styles-wrapper {
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-jp);
    &:not(:first-child) {
      margin-top: 2em;
      @include mq(sp) {
        margin-top: 1.5em;
      }
    }
    &:not(:last-child) {
      margin-bottom: 1em;
      @include mq(sp) {
        margin-bottom: .75em;
      }
    }
  }
  h1,
  h2 {
    font-size: rem(32);
    @include mq(sp) {
      font-size: sprem(26);
    }
  }
  h3 {
    font-size: calc-fz(26);
    @include mq(sp) {
      font-size: sprem(22);
    }
  }
  h4 {
    font-size: calc-fz(20);
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
  h5 {
    font-size: calc-fz(18);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
  h6 {
    font-size: calc-fz(17);
    @include mq(sp) {
      font-size: sprem(15);
    }
  }
}

.editor-styles-wrapper ol,
.mce-content-body ol {
  list-style: decimal;
}
.editor-styles-wrapper ul,
.editor-styles-wrapper ol,
.mce-content-body ul,
.mce-content-body ol {
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.editor-styles-wrapper ul li,
.editor-styles-wrapper ol li,
.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin-left: perc(40,16,em) !important;
  padding-top: .5em;
  &::marker {
    color: var(--clr-primary-default);
    font-weight: 600;
    letter-spacing: perc(3.2,16,em);
  }
}
.editor-styles-wrapper ul li,
.mce-content-body ul li {
  list-style: none;
  &::before {
    content: '';
    position: absolute;
    top: perc(17,16,em);
    left: perc(-34,16,em);
    width: perc(10,16,em);
    aspect-ratio: 1/1;
    border: 1px solid var(--clr-primary-default);
    border-radius: 50%;
    background-color: var(--clr-primary-default);
  }
}
.editor-styles-wrapper ul[style="list-style-type: circle;"] li::before,
.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: var(--clr-wht);
}
.editor-styles-wrapper ol,
.mce-content-body ol {
  list-style-type: decimal-leading-zero;
}
.editor-styles-wrapper .wp-block-image img,
.mce-content-body .wp-block-image img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}
.editor-styles-wrapper .wp-caption,
.mce-content-body .wp-caption {
  width: fit-content;
  max-width: 100%;
}
.editor-styles-wrapper hr,
.mce-content-body hr {
  margin-top: rem(32);
  margin-bottom: rem(32);
  border: none;
  height: 2px;
  background-color: var(--clr-primary-light);
  border-radius: 50vw;
}
.editor-styles-wrapper a .wp-block-image img,
.mce-content-body a .wp-block-image img {
  transition: var(--transit-default);
}
.editor-styles-wrapper a:hover .wp-block-image img,
.mce-content-body a:hover .wp-block-image img {
  opacity: .8;
}
.editor-styles-wrapper .alignleft,
.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}
.editor-styles-wrapper .alignright,
.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}
.editor-styles-wrapper .aligncenter,
.mce-content-body .aligncenter {
  display: block;
  @include auto-margin;
  margin-bottom: 1em;
}
.editor-styles-wrapper .wp-block-image img.aligncenter,
.mce-content-body .wp-block-image img.aligncenter {
  display: block;
}
.editor-styles-wrapper .alignleft:last-child,
.editor-styles-wrapper .alignright:last-child,
.editor-styles-wrapper .aligncenter:last-child,
.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}
.editor-styles-wrapper p:not(:last-child),
.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
}
.editor-styles-wrapper strong,
.mce-content-body strong {
  font-weight: bold;
}
.editor-styles-wrapper blockquote,
.mce-content-body blockquote {
  margin: 1em 0;
  padding: rem(16) rem(40);
  border-radius: rem(3);
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: var(--clr-bg-02);
  text-align: left;
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 1em;
    height: 1em;
    opacity: .25;
  }
  &::before {
    background-image: url('../images/common/icon-quote_left.svg');
    top: rem(14);
    left: rem(12);
  }
  &::after {
    background-image: url('../images/common/icon-quote_right.svg');
    bottom: rem(14);
    right: rem(12);
  }
}
.editor-styles-wrapper blockquote p,
.mce-content-body blockquote p { margin-bottom: 0; }
.editor-styles-wrapper a,
.mce-content-body a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.editor-styles-wrapper table,
.mce-content-body table {
  border: none;
  height: auto !important;
  &:not(:first-child) {
    margin-top: 1em;
  }
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.editor-styles-wrapper th,
.editor-styles-wrapper td,
.editor-styles-wrapper tr,
.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}
.editor-styles-wrapper th,
.editor-styles-wrapper td,
.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid #D3D3D3;
  vertical-align: top;
}
.editor-styles-wrapper th,
.mce-content-body th {
  min-width: rem(120);
  text-align: left;
}
.editor-styles-wrapper sup,
.mce-content-body sup {
  font-size: calc-fz(12);
  color: #555;
}
.bold {
  font-weight: bold;
  color: var(--clr-primary-default);
}

/* iframe */
.editor-styles-wrapper iframe,
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
}

@include mq(sp) {
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .editor-styles-wrapper blockquote,
  .mce-content-body blockquote {
    padding: rem(16);
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      bottom: 0;
      right: 0;
    }
  }
  .editor-styles-wrapper table,
  .mce-content-body table {
    max-width: none !important;
  }
  .editor-styles-wrapper th,
  .mce-content-body th {
    font-weight: bold;
  }
  .editor-styles-wrapper th,
  .editor-styles-wrapper td,
  .mce-content-body th,
  .mce-content-body td {
    padding: .5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }

  /* table scroll */
  .table-sp-scroll {
    width: sprem(700);
  }
  .scroll {
    overflow-x: scroll;
    margin-bottom: 1em;
    -webkit-overflow-scrolling: touch;
  }
  .scroll table { margin: 0; }
  .scroll--cap {
    text-align: right;
    margin: 1em 0 0 !important;
    opacity: .25;
    font-size: calc-fz(14);
  }
}

/* ブロックスタイル
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3,
.is-style-editor-ttl,
.is-style-editor-ttl2,
.is-style-editor-ttl3 {
  font-family: var(--font-jp);
  &:not(:first-child) {
    margin-top: 2em;
    @include mq(sp) {
      margin-top: 1.5em;
    }
  }
  &:not(:last-child) {
    margin-bottom: 1em;
    @include mq(sp) {
      margin-bottom: .75em;
    }
  }
}
.editor-ttl,
.is-style-editor-ttl {
  font-size: rem(32);
  padding-bottom: rem(8);
  border-bottom: 2px dashed var(--clr-primary-default);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.editor-ttl2,
.is-style-editor-ttl2 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.editor-ttl3,
.is-style-editor-ttl3 {
  color: var(--clr-org5);
  font-size: calc-fz(18);
  @include mq(sp) {
    font-size: sprem(16);
  }
}

/* 注意書き */
.txt-attention,
.is-style-txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: calc-fz(14);
  opacity: .6;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &::before {
    content: '※';
  }
}

/* テーブル */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: calc(100% - rem(3));
  }
}
.table1 th {
  color: var(--clr-primary-default);
  &::before {
    left: 0;
    border-bottom: 2px solid;
  }
}
.table1 td::before {
  right: 0;
  border-bottom: 1px solid #D3D3D3;
}
.table2 {
  border-radius: rem(16);
  border-collapse: separate;
  background-color: var(--clr-wht);
  tr:nth-of-type(odd) td {
    background-color: var(--clr-bg-02);
  }
  th {
    font-family: var(--font-primary-500);
    color: var(--clr-primary-default);
    background-color: var(--clr-bg-01);
  }
  th,
  td {
    border-color: var(--clr-primary-default);
    border-right-width: 0;
    border-bottom-width: 0;
  }
  tr :is(th, td):last-child {
    border-right-width: 1px;
  }
  tr:last-child th,
  tr:last-child td {
    border-bottom-width: 1px;
  }

  /* --- 角丸 --- */
  tr:first-child :is(th, td):first-child{
    border-radius: rem(16) 0 0 0;
  }
  tr:first-child :is(th, td):last-child {
    border-radius: 0 rem(16) 0 0;
  }
  tr:last-child :is(th, td):first-child {
    border-radius: 0 0 0 rem(16);
  }
  tr:last-child :is(th, td):last-child {
    border-radius: 0 0 rem(16) 0;
  }

  /* 一行の場合 */
  tr:only-child :is(th, td):first-child {
    border-radius: rem(16) 0 0 rem(16) !important;
  }
  tr:only-child :is(th, td):last-child {
    border-radius: 0 rem(16) rem(16) 0 !important;
  }

  /* 一列の場合 */
  tr:first-child :is(th, td):only-child {
    border-radius: rem(16) rem(16) 0 0 !important;
  }
  tr:last-child :is(th, td):only-child {
    border-radius: 0 0 rem(16) rem(16) !important;
  }
  @include mq(sp) {
    &.table-sp-block :is(th, td) {
      border-radius: 0 !important;
    }
    &.table-sp-block th,
    &.table-sp-block td {
      border-right-width: 1px;
    }
    &.table-sp-block tr:first-child :is(th, td):first-child {
      border-radius: rem(16) rem(16) 0 0 !important;
    }
    &.table-sp-block tr:last-child :is(th, td):last-child {
      border-radius: 0 0 rem(16) rem(16) !important;
    }
  }
}

/* ボックス */
.box,
.is-style-box {
  padding: rem(24) rem(32);
  background-color: var(--clr-wht);
  border-radius: rem(8);
  @include mq(sp) {
    padding: rem(24);
  }
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
  *:not([class*="clr-"]):not([class*="bold"]):not([class*="editor-ttl"]) {
    color: var(--clr-text-primary);
  }
}
.bg-wht .box,
.bg-wht .is-style-box {
  background-color: var(--clr-bg-01);
}
.lps_sec:nth-child(even),
.lps_sec[style*="background-color"] {
  *:not(.bg-wht) .box {
    background-color: var(--clr-wht);
  }
}

/* リスト */
.list-circle::before,
.editor-styles-wrapper ul .is-style-list-circle::before {
  background-color: transparent;
}
.list-square::before,
.editor-styles-wrapper ul .is-style-list-square::before {
  border-radius: 0;
}
.list-check,
.editor-styles-wrapper ul .is-style-list-check {
  &::before,
  &::after {
    content: '';
    position: absolute;
  }
  &::before {
    top: perc(10,16,em);
    left: perc(-40,16,em);
    width: perc(24,16,em);
    aspect-ratio: 1/1;
    background-color: var(--clr-primary-default);
    border-radius: 50%;
  }
  &::after {
    top: perc(17,16,em);
    left: perc(-33,16,em);
    width: perc(10,16,em);
    height: perc(7,16,em);
    border-left: 2px solid var(--clr-wht);
    border-bottom: 2px solid var(--clr-wht);
    transform: rotate(-45deg);
  }
}

@include mq(sp) {
  /* テーブル */
  .table1.table-sp-block td::before {
    display: none;
  }
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid var(--clr-primary-default);
  }
}

/* 管理画面エディタ用
*************************************************** */
h1.editor-post-title__input {
  padding: 32px 0;
  font-size: rem(40);
  font-family: var(--font-jp);
}
.editor-styles-wrapper,
.editor-styles-wrapper * {
  font-family: YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
}
.editor-styles-wrapper {
  font-size: 16px;
}

@include mq(sp) {
  .editor-styles-wrapper table tr *:first-child:nth-last-child(2),
  .editor-styles-wrapper table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}

/* コアブロック
********************************************** */
.has-text-align-left {
  text-align: left;
}
.has-text-align-center {
  text-align: center;
}
.has-text-align-right {
  text-align: right;
}
.em {
  font-style: italic;
}

/* デフォルトの機能
********************************************** */
.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%;
}
.wp-block-table.aligncenter table,
.wp-block-table.alignleft table,
.wp-block-table.alignright table {
  display: table;
  width: auto;
}
.wp-block[data-align=center] > .wp-block-table table {
  margin: 0 auto;
}