@use '../abstracts' as *;

/* lps_parts--cta
********************************************** */
.lps_parts--cta .inner {
  background-color: var(--clr-bg-02);
  border-radius: rem(40);
}
.lps_parts--cta .bg {
  position: absolute;
  bottom: 0;
  inset-inline: 0;
  height: rem(520);
  background-position: center;
  background-size: cover;
  @include mq(sp) {
    height: sprem(350);
  }
}
.lps_parts--cta .cta_tel a {
  width: 100%;
}