@use "sass:math";
@use '../abstracts' as *;

@for $i from 0 through 150 {
  .mgn#{$i} { margin: rem($i); }
  .mgn-top#{$i} { margin-top: rem($i); }
  .mgn-rgt#{$i} { margin-right: rem($i); }
  .mgn-btm#{$i} { margin-bottom: rem($i); }
  .mgn-lft#{$i} { margin-left: rem($i); }
}

@include mq(sp) {
  @for $i from 0 through 150 {
    $halfValue: math.div(math.div($i, 2), $fz-sp); // $iの半分の値を計算
    .mgn#{$i} { margin: #{$halfValue}rem; }
    .mgn-top#{$i} { margin-top: #{$halfValue}rem; }
    .mgn-rgt#{$i} { margin-right: #{$halfValue}rem; }
    .mgn-btm#{$i} { margin-bottom: #{$halfValue}rem; }
    .mgn-lft#{$i} { margin-left: #{$halfValue}rem; }
  }
}

@for $i from 0 through 15 {
  .mgn#{$i}em { margin: #{$i}em; }
  .mgn-top#{$i}em { margin-top: #{$i}em; }
  .mgn-rgt#{$i}em { margin-right: #{$i}em; }
  .mgn-btm#{$i}em { margin-bottom: #{$i}em; }
  .mgn-lft#{$i}em { margin-left: #{$i}em; }
}