@use '../abstracts' as *;

/* section-contact
********************************************** */
.section-contact {
  .cta_tel {
    width: 100%;
    max-width: rem(400);
    @include auto-margin;
  }
  .cta_tel a {
    padding: rem(24) rem(40);
    margin-inline: auto;
    @include mq(sp) {
      display: block;
      padding: sprem(16) sperm(24);
    }
  }
}

.nf-form-content {
  .listcheckbox-wrap .nf-field-element label:after {
    background-color: var(--clr-wht);
  }
}