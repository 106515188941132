@use '../abstracts' as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default);
}
.icon-new_tab {
  @include rect(14);
}
.icon-pdf {
  @include rect(27,12);
}
.lps_sec {
  position: relative;
  padding-top: rem(40);
  padding-bottom: calc(rem(80) + vw(105));

  --deco-color: var(--clr-bg-01);
  &:has(+ .section-related_page) {
    padding-bottom: rem(40);
  }
  &:first-child {
    padding-top: rem(80);
  }
  &:nth-child(odd) {
    .bg-wht {
      background-color: var(--clr-wht);
      --deco-color: var(--clr-wht);
    }
    & + .section-related_page {
      background-color: var(--clr-bg-01);
      --deco-color: var(--clr-bg-01);
    }
  }
  &:nth-child(even) {
    background-color: var(--clr-bg-03);
    --deco-color: var(--clr-bg-03);
    & + .section-related_page {
      background-color: var(--clr-bg-03);
      --deco-color: var(--clr-bg-03);
    }
  }
  &:not(:first-child)::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    translate: 0 -100%;
    width: 100%;
    height: vw(105);
    aspect-ratio: 1920/105;
    background-color: var(--deco-color);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/section-mask-01.svg);
    mask-size: 100% auto;
    mask-position: center top;
    mask-repeat: no-repeat;
  }
  &.section-related_page {
    &::after {
      content: none !important;
    }
  }
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
  &:not(:last-child) {
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(40);
    }
  }
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: rem(24);
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
}