@use "../abstracts" as *;

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a {
  position: relative;
  z-index: 1;
  @include center-flex;
  padding: rem(16) rem(58) rem(16) rem(40);
  border-radius: 9999px;
  width: fit-content;
  min-width: min(rem(200), 100%);
  font-family: var(--font-primary-500);
  line-height: 1.375;
  text-align: center;
  color: var(--clr-primary-default);
  border: 3px solid var(--clr-primary-default);
  background-color: var(--clr-wht );
  @include mq(sp) {
    min-width: 100%;
  }
  &:where(:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]):not(:has(.icon-svg)))::before,
  &:where(:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]):not(:has(.icon-svg)))::after,
  &.arrow::before,
  &.arrow::after {
    content: "";
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    transition: var(--transit-default);
  }
  &:where(:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]):not(:has(.icon-svg)))::before,
  &.arrow::before {
    right: rem(24);
    @include rect(18);
    background-color: currentColor;
    border-radius: 50vw;
  }
  &:where(:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]):not(:has(.icon-svg)))::after,
  &.arrow::after {
    right: rem(31);
    @include rect(4,6);
    clip-path: polygon(0 0, 1.5px 0, 100% 50%, 1.5px 100%, 0 100%, calc(100% - 1.5px) 50%);
    background-color: var(--clr-wht);
  }
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
  }
  &:hover:where(:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::after,
  &:hover.arrow::after {
    background-color: var(--clr-primary-default);
  }
  svg {
    right: rem(24);
    fill: currentColor;
  }
  svg.icon-pdf {
    right: rem(16);
  }
}
.btn.btn-ctr a {
  @include auto-margin;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-primary-default);
  background-color: var(--clr-wht);
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
  }
  svg {
    fill: var(--clr-primary-default);
  }
  &:hover svg {
    fill: var(--clr-wht);
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--clr-secondary-default);
  &:hover {
    background-color: var(--clr-secondary-dark);
  }
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  margin-inline: -1em;
  padding: 1em;
  &:hover {
    color: var(--clr-primary-default);
  }
  &::after {
    content: "";
    @include rect(8);
    color: var(--clr-primary-default);
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
  }
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280), 100%);
    }
  }
  a {
    min-width: 100%;
    height: 100%;
  }
}
