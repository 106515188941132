@use "../abstracts" as *;

/* home commomn
**************************************** */

/* --- home_sec --- */
.home_section {
  position: relative;
  @include mq(sp) {
    @include mq(sp) {
      @include p-tb(sprem(32),sprem(96));
    }
  }
  &:not([class*="bg"]) {
    --deco-color: var(--clr-bg-01);
  }
  &:not(:first-of-type)::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    translate: 0 -100%;
    width: 100%;
    height: vw(105);
    aspect-ratio: 1920/105;
    background-color: var(--deco-color);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/section-mask-01.svg);
    mask-size: 100% auto;
    mask-position: center top;
    mask-repeat: no-repeat;
  }
}

/* --- home_ttl --- */
.home_ttl {
  @include center-flex;
  flex-direction: column;
  gap: rem(24);
  font-family: var(--font-primary-500);
  font-size: rem(56);
  text-align: center;
  @include mq(sp) {
    font-size: sprem(28);
    gap: sprem(8);
    margin-bottom: sprem(24);
  }
  .font-en {
    @include center-flex;
    gap: rem(24);
    font-family: var(--font-en);
    font-weight: 700;
    font-size: rem(18);
    color: var(--clr-primary-default);
    @include mq(sp) {
      font-size: sprem(16);
    }
    &::before,
    &::after {
      content: '';
      height: rem(16);
      width: 2px;
      border: 1px solid currentColor;
      border-radius: 50vw;
      transform-origin: center;
    }
    &::before {
      rotate: -15deg;
    }
    &::after {
      rotate: 15deg;
    }
  }
}
.ttl-org {
  font-size: rem(40);
  color: var(--clr-primary-light);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.ttl-wht {
  color: var(--clr-wht);
  .font-en {
    color: currentColor;
  }
}
.home_ttl--dot {
  display: flex;
  justify-content: center;
  gap: rem(8);
  &::after,
  &::before {
    content: '';
  }
  span,
  &::after,
  &::before {
    display: block;
    @include rect(6);
    border-radius: 50vw;
    background-color: var(--clr-primary-light);
  }
  &.dot-lft {
    justify-content: flex-start;
  }
}

.home_ttl-02 {
  display: flex;
  flex-direction: column;
  font-size: rem(32);
  font-family: var(--font-primary-500);
  line-height: 1.7;
  @include mq(sp) {
    font-size: sprem(24);
  }
  .font-en {
    margin-bottom: rem(8);
    font-size: rem(18);
    font-weight: 700;
    line-height: 1.6;
    color: var(--clr-primary-default);
    @include mq(sp) {
      font-size: sprem(16);
      margin-bottom: sprem(4);
    }
  }
}

.home_txt p + p {
  margin-top: rem(24);
}

/* hero
********************************************** */
.hero {
  position: relative;
  padding-top: rem(40);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: var(--clr-wht);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn01.jpg);
  background-color: var(--clr-primary-light);
  mask-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl-mask.svg);
  mask-size: cover;
  mask-position: center bottom;
  z-index: 10;
  @include mq(sp) {
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl-mask_sp.svg);
    mask-size: cover;
    mask-position: center bottom;
  }
  @include mq(sp, min, ps) {
    height: rem(900);
  }
  @include mq(sp) {
    // height: sprem(640);
    padding: sprem(80) 7% sprem(56);
  }
}
.hero_copy {
  margin-bottom: rem(32);
  @include mq(sp) {
    font-size: sprem(14);
    margin-bottom: sprem(24);
  }
}
.hero_copy--ttl {
  font-family: var(--font-primary-500);
  font-size: rem(72);
  line-height: 1.4;
  margin-bottom: rem(16);
  text-shadow: 0px 0px 16px var(--clr-org3);
  @include mq(sp) {
    font-size: sprem(40);
    text-align: center;
  }
  .small-1 {
    font-family: var(--font-primary-300);
    font-size: rem(64);
    @include mq(sp) {
      font-size: sprem(36);
    }
  }
  .small-2 {
    font-size: rem(56);
    letter-spacing: 0.01em;
    @include mq(sp) {
      font-size: sprem(30);
    }
  }
}
.hero_copy--dot {
  display: flex;
  gap: rem(8);
  &::after,
  &::before {
    content: '';
  }
  span,
  &::after,
  &::before {
    display: block;
    @include rect(6);
    border-radius: 50vw;
    background-color: rgba(255, 255, 255, 0.6);
  }
}
.hero_read {
  margin-bottom: rem(48);
  font-family: var(--font-primary-500);
  font-size: rem(18);
  line-height: 2;
  @include mq(sp) {
    margin-bottom: sprem(80);
  }
}

/* hero_cta
**************************************** */
.hero_cta {
  position: relative;
  width: 86%;
  max-width: 720px;
  display: grid;
  padding: rem(24) rem(40);
  margin-bottom: rem(24);
  color: var(--clr-text-primary);
  background-color: var(--clr-wht);
  border-radius: rem(24);
  box-shadow: 0px 4px 14px 0px rgba(253, 15, 0, 0.10);
  @include mq(sp, min, ps) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: rem(80);
  }
  @include mq(sp) {
    width: 100%;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    gap: sprem(16);
  }
  @include mq(sp, min, ps) {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      width: 1px;
      height: calc(100% - rem(24) * 2);
      border-left: 1px dashed var(--clr-primary-default);
    }
  }
}
.hero_cta--item {
  display: grid;
  grid-row: span 3;
  gap: rem(16);
  @include mq(sp, min, ps) {
    grid-template-rows: subgrid;
  }
  @include mq(sp) {
    gap: sprem(8);
    &:first-child {
      padding-bottom: sprem(16);
      border-bottom: 1px dashed var(--clr-primary-default);
    }
  }
}
.hero_cta--ttl {
  width: fit-content;
  margin-inline: auto;
  padding: rem(4) rem(24);
  color: var(--clr-wht);
  line-height: 2;
  text-align: center;
  background-color: var(--clr-primary-default);
  border-radius: 50vw;
}
.hero_cta--content {
  @include center-flex;
  p {
    line-height: 1.8;
  }
}
.hero-cta--btn {
  @include center-flex;
  gap: rem(16);
  .btn a {
    padding: rem(10) rem(60) rem(10) rem(24);
  }
}
.hero_cta--item.tel .hero_cta--content a {
  @include center-flex;
  flex-direction: column;
  gap: rem(4);
  font-family: var(--font-primary-500);
  line-height: 1.4;
  .text {
    font-size: rem(18);
  }
  .tel {
    display: flex;
    gap: rem(8);
    color: var(--clr-primary-default);
    font-size: rem(24);
  }
  .teltime {
    padding: rem(6) rem(20);
    font-size: rem(14);
    font-family: var(--font-primary-600);
    color: var(--clr-sub-03);
    line-height: 1.6;
    background-color: var(--clr-org2);
    border-radius: 50vw;
  }
}
.hero_cta--item.involved {
  .hero_cta--ttl {
    background-color: var(--clr-org);
  }
  .btn a {
    color: var(--clr-org);
    border-color: var(--clr-org);
    &:hover {
      color: var(--clr-wht);
      background-color: var(--clr-org);
    }
    &:hover::after {
      background-color: var(--clr-org);
    }
  }
}

/* --- hero_scroll --- */
.hero_scroll {
  @include center-flex;
  flex-direction: column;
  gap: rem(8);
  @include mq(sp) {
    display: none;
  }
}
.hero_scroll--txt {
  font-size: rem(9);
  font-weight: 700;
  letter-spacing: 0.0833em;
  opacity: 0.95;
}

/* --- hero_img --- */
.hero_img {
  position: absolute;
  z-index: -1;
  @include mq(sp, min, ps) {
    &.img01 {
      left: 0;
      top: rem(24);
    }
    &.img02 {
      left: 0;
      top: rem(324);
    }
    &.img03 {
      left: 0;
      top: rem(640);
    }
    &.img04 {
      left: rem(306);
      top: rem(176);
    }
    &.img05 {
      left: rem(306);
      top: rem(520);
    }
    &.img06 {
      right: rem(332);
      top: rem(168);
    }
    &.img07 {
      right: rem(332);
      top: rem(512);
    }
    &.img08 {
      right: 0;
      top: rem(32);
    }
    &.img09 {
      right: 0;
      top: rem(324);
    }
    &.img10 {
      right: 0;
      top: rem(640);
    }
  }
  @include mq(sp) {
    &.img02,
    &.img03,
    &.img05,
    &.img07,
    &.img09,
    &.img10 {
      display: none;
    }
    &.img01 {
      width: calc(spvw(132) * 0.6) !important;
      left: sprem(8);
      top: sprem(16);
    }
    &.img04 {
      width: calc(spvw(154) * 0.6) !important;
      left: sprem(8);
      top: sprem(416);
    }
    &.img06 {
      width: calc(spvw(154) * 0.6) !important;
      right: sprem(8);
      top: sprem(408);
    }
    &.img08 {
      width: calc(spvw(138) * 0.6) !important;
      right: 0;
      top: sprem(24);
    }
  }
}

/* home_message
********************************************** */
.home_message {
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/bg-message.png);
  background-size: 100% auto;
  @include mq(sp, min, ps) {
    @include p-tb(rem(40), rem(240));
  }
  @include mq(sp) {
    @include p-tb(sprem(80), sprem(120))
  }
}
.home_message--box {
  padding: rem(96) rem(200) rem(328);
  border-radius: rem(220);
  background-color: var(--clr-wht);
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/bg-message02.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  box-shadow: 0px 0px 17px 0px rgba(253, 15, 0, 0.05);
  @include mq(sp) {
    padding: sprem(64) sprem(24) sprem(120);
    border-radius: sprem(64);
  }
}
.home_message--ttl .home_ttl {
  line-height: 1.6;
  .underline {
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: rem(4);
      translate: -50% 0;
      width: 100%;
      height: rem(16);
      background-color: var(--clr-yel);
      border-radius: 50vw;
      z-index: -1;
    }
  }
}
.home_message--txt {
  font-family: var(--font-primary-500);
  font-size: rem(18);
  line-height: 2.1;

}
.home_message--deco {
  position: absolute;
  &.deco01 {
    @include mq(sp, min, ps) {
      top: rem(168);
      left: rem(109);
    }
    @include mq(sp) {
      width: calc(spvw(198) * 0.4) !important;
      left: sprem(8);
      top: sprem(16);
    }
  }
  &.deco02 {
    @include mq(sp, min, ps) {
      top: rem(540);
      right: rem(20);
    }
    @include mq(sp) {
      width: calc(spvw(327) * 0.4) !important;
      right: 0;
      bottom: sprem(32);
    }
  }
  &.deco03 {
    @include mq(sp, min, ps) {
      bottom : rem(160);
      left: rem(128);
    }
    @include mq(sp) {
      width: calc(spvw(181) * 0.4) !important;
      left: sprem(8);
      bottom: sprem(48);
    }
  }
}

/* home_what
********************************************** */
.home_what {
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/bg-what.png);
  background-size: 100% auto;
  @include mq(sp, min, ps) {
    @include p-tb(rem(48),rem(340));
  }
}
.home_what--item {
  display: flex;
  align-items: center;
  gap: rem(80);
  padding: rem(40) rem(88) rem(40) rem(40);
  background-color: var(--clr-wht);
  border-radius: rem(30);
  @include mq(sp) {
    flex-direction: column;
    gap: sprem(24);
    padding: sprem(32) sprem(24);
  }
  &:not(:last-child) {
    margin-bottom: rem(16);
  }
  & > * {
    @include mq(sp, min, ps) {
      width: calc((100% - rem(80)) / 2);
    }
  }
}
.home_what--text {
  flex: 1;
}
.home_what--ttl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: rem(16);
  margin-bottom: rem(24);
  font-family: var(--font-primary-500);
  font-size: rem(40);
  line-height: 1.6;
  @include mq(sp) {
    font-size: sprem(28);
  }
}
.btn-home_what a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: rem(32);
  width: rem(336);
  padding: rem(5) rem(80) rem(5) rem(5);
  .icon {
    @include center-flex;
    width: rem(64);
    aspect-ratio: 1;
    background-color: currentColor;
    border-radius: 50vw;
  }
  .icon svg * {
    fill: var(--clr-wht);
  }
  .txt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(4);
    font-family: var(--font-primary-500);
    font-size: rem(18);
    line-height: 1;
  }
  .font-en {
    font-weight: 700;
    font-size: rem(12);
    line-height: 1.7;
    opacity: 0.4;
  }
  &:hover {
    .icon svg * {
      fill: var(--clr-primary-default);
    }
  }
}

/* home_feature
********************************************** */
.home_feature {
  @include mq(sp, min, ps) {
    @include p-tb(rem(80), rem(280));
  }
}
.home_feature--item {
  padding: rem(48) rem(40);
  background-color: var(--clr-wht);
  box-shadow: 0px 5px 17px 0px rgba(253, 15, 0, 0.05);
  border-radius: rem(30);
}
.feature_ttl--box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: rem(8);
  @include mq(sp, min, ps) {
    @include mq(1200) {
      flex-direction: column-reverse;
      gap: rem(24);
    }
  }
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
  .home_ttl-02 {
    flex: 1;
  }
}

/* home_about
********************************************** */
.home_about {
  @include mq(sp, min, ps) {
    @include p-tb(rem(16),rem(280));
  }
}
.home_about--wrap {
  @include mq(sp) {
    flex-direction: column-reverse;
  }
}
.home_about--content {
  @include mq(sp, min, ps) {
    max-width: rem(620);
    padding-bottom: rem(64);
  }
}
.home_about--link a {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: rem(8);
  height: rem(144);
  @include p-lr(rem(32));
  font-family: var(--font-primary-500);
  line-height: 1;
  color: var(--clr-primary-default);
  background-color: var(--clr-wht);
  border-radius: rem(14);
  box-shadow: 0px 5px 17px 0px rgba(253, 15, 0, 0.05);
  @include mq(sp) {
    height: sprem(80);
  }
  .font-en {
    font-size: rem(12);
    font-weight: 700;
    line-height: 1.7;
    opacity: 0.4;
    @include mq(sp) {
      line-height: 1;
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    transition: var(--transit-default);
  }
  &::before {
    right: rem(24);
    @include rect(18);
    background-color: currentColor;
    border-radius: 50vw;
  }
  &::after {
    right: rem(31);
    @include rect(4,6);
    clip-path: polygon(0 0, 1.5px 0, 100% 50%, 1.5px 100%, 0 100%, calc(100% - 1.5px) 50%);
    background-color: var(--clr-wht);
  }
  &:hover {
    background-color: var(--clr-bg-03);
  }
}

/* home_information
********************************************** */
.home_information {
  background-image: url(/wp/wp-content/themes/standard_sass/images/top/bg-info.png);
  background-size: 100% auto;
  @include mq(sp, min, ps) {
    @include p-tb(rem(96),rem(240));
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50vh;
    top: 2px;
    translate: 0 -100%;
    background-color: var(--clr-bg-03);
    z-index: 1;
  }
}
.home_information--post {
  @include p-tb(rem(32),rem(24));
  overflow: hidden;
  @include mq(sp) {
    @include p-tb(sprem(32),sprem(24));
  }
}
.home_information--slide {
  display: flex;
  justify-content: center;
  .slick-list {
    overflow: visible;
  }
  .post {
    display: flex;
    flex-direction: column;
    margin-inline: rem(12);
    opacity: 0.5;
    transition: var(--transit-default);
    &.slick-active {
      opacity: 1;
    }
  }
  .post--img {
    border-radius: rem(16);
    overflow: hidden;
    img {
      transition: var(--transit-default);
    }
  }
  .post--img:has(.no-img) {
    border: 1px solid var(--clr-primary-default);
  }
  .post--ttl {
    font-family: var(--font-primary-500);
    font-size: rem(20);
  }
  .post:hover .post--img img {
    scale: 1.03;
  }
  .slick-arrow {
    position: absolute;
    top: 0;
    width: rem(80);
    transition: var(--transit-default);
    z-index: 10;
    @include mq(sp) {
      width: rem(48);
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      width: rem(80);
      aspect-ratio: 1;
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/ico-slide-arrow.svg);
      background-position: center;
      background-size: contain;
      border-radius: 50vw;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
      @include mq(sp) {
        width: sprem(40);
      }
    }
    &:hover {
      @include mq(sp, min, ps) {
        opacity: 0.8;
      }
    }
  }
  .slick-prev {
    display: none !important;
    left: rem(-3);
    translate: -50% 0;
    @include mq(sp) {
      left: 0;
    }
  }
  .slick-next {
    right: rem(-3);
    translate: 50% 0;
    rotate: 180deg;
    @include mq(sp) {
      right: 0;
    }
  }
}
.home_information--link {
  position: relative;
  padding: rem(48) rem(64) rem(32);
  background: var(--clr-wht);
  border-radius: rem(30);
  box-shadow: 0px 5px 17px 0px rgba(253, 15, 0, 0.05);
  @include mq(sp) {
    padding: sprem(40) rem(24) sprem(24);
  }
}
.information_link--text {
  @include mq(sp, min, ps) {
    width: perc(332,576,'%');
  }
}
.information_link--ttl {
  font-size: rem(40);
  @include mq(sp) {
    font-size: sprem(28);
    margin-bottom: sprem(32);
  }
}
.information_link--img {
  position: absolute;
  right: 0;
}
.home_information--link .information_link--img {
  @include mq(sp, min, ps) {
    top: rem(24);
    right: rem(16);
  }
  @include mq(sp) {
    width: sprem(100);
    top: sprem(8);
    right: sprem(32);
  }
}