@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  background-color: var(--clr-wht);
  z-index: z(header);
  transition: var(--transit-default);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: vw(64);
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
    & > .inner {
      padding-left: rem(16);
    }
  }
}
.template-contact-simple .header {
  position: absolute;
}
.header-sm {
  @include mq(med, min, ps) {
    height: rem($header-hgt-fx);
  }
}

/* --- logo -- */
.header--logo a,
.footer--logo a {
  display: inline-block;
  &:hover {
    opacity: 0.7;
  }
}
.header--logo img {
  @include mq(med) {
    width: auto !important;
    max-height: rem(56);
  }
}

/* gnav
********************************************** */
.gnav,
.gnav ul,
.gnav li,
.gnav a {
  @include mq(med, min, ps) {
    height: 100%;
  }
}
.gnav {
  @include mq(med, min, ps) {
    display: flex;
    align-items: center;
    gap: rem(24);
  }
  @include mq(med) {
    position: fixed;
    top: sprem($header-hgt-sp);
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100% - sprem($header-hgt-sp));
    margin: 0;
    padding: rem(24) 7%;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    background-color: var(--clr-wht);
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(0);
    }
  }
}
.gnav--menu {
  @include mq(med) {
    margin-bottom: sprem(32);
  }
}
.gnav a {
  @include mq(med, min, ps) {
    @include center-flex;
  }
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  @include mq(med) {
    display: block;
  }
}
.gnav > .gnav--menu {
  @include mq(med, min, ps) {
    height: calc(100% - rem(16));
    gap: vw(16)
  }
}
.gnav--menu li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px dotted var(--clr-primary-default);
    }
  }
}
.gnav--menu > li > .gnav--link {
  position: relative;
  @include p-lr(rem(32));
  padding-bottom: rem(8);
  font-family: var(--font-primary-500);
  border-radius: rem(5);
  @include mq(1400) {
    @include p-lr(vw(24));
  }
  @include mq(med) {
    padding: sprem(12) sprem(16);
  }
  @include mq(med, min, ps) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: vw(-8);
      translate: 0 -50%;
      width: 1px;
      height: rem(96);
      border-left: 1px dashed var(--clr-primary-default);
    }
  }
}
.gnav--menu > li > .gnav--link > span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  gap: rem(8);
  @include mq(med, min, ps) {
    flex-direction: column;
  }
  @include mq(med) {
    justify-content: flex-start;
    gap: sprem(16);
    img {
      width: sprem(32) !important;
      aspect-ratio: 1;
      object-fit: contain;
    }
  }
}
.menu-image-title-after.menu-image-title {
  padding-left: 0;
}
.gnav--link {
  @include mq(med) {
    display: block;
    padding: rem(18) rem(16);
  }
  @include mq(med, min, ps) {
    text-align: center;
  }
}
.gnav--menu li:not(.menu-item-has-children) a::before {
  @include mq(med) {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: sprem(26);
    margin: auto;
    @include sprect(7);
    border-top: 1px solid var(--clr-primary-default);
    border-right: 1px solid var(--clr-primary-default);
    transform: rotate(45deg);
  }
}
.gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
.gnav--menu > li.current-menu-item > .gnav--link {
  @include mq(med, min, ps) {
    color: var(--clr-wht);
    background-color: var(--clr-text-primary);
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}
.head_sub_nav--wrap {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 100;
  transition: var(--transit-default);
  opacity: 0;
  pointer-events: none;
  @include mq(med) {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-primary-default);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
}
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    opacity: 1;
    pointer-events: auto;
  }
}
.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav {
  @include mq(med) {
    position: relative;
  }
}
.head_sub_nav a {
  display: block;
  padding: rem(16) rem(24);
  @include mq(med, min, ps) {
    background-color: var(--clr-wht);
  }
  &:hover {
    @include mq(med, min, ps) {
      color: var(--clr-primary-default);
    }
  }
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: 0;
    right: 0;
    @include sprect(56);
    transition: var(--transit-default);
    color: var(--clr-primary-default);
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: sprem(24);
      margin: auto;
      width: rem(11);
      height: 1px;
      border-bottom: 1px solid;
    }
    &::after {
      transform: rotate(90deg);
      transition: var(--transit-default);
    }
    &.active {
      transform: rotate(180deg);
    }
    &.active::after {
      opacity: 0;
    }
  }
}

/* ---btn --- */
.gnav_btn,
.tel_btn {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: fixed;
    top: 0;
    @include sprect(64);
    cursor: pointer;
    z-index: 9999;
  }
}
.gnav_btn {
  @include mq(med) {
    right: 0;
    transition: var(--transit-default);
    &.active {
      background-color: var(--clr-wht);
    }
  }
}
.gnav_btn--lines {
  @include mq(med) {
    position: relative;
    @include sprect(24, 16);
  }
}
.gnav_btn--lines span {
  @include mq(med) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-primary-default);
    transition: var(--transit-default);
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.active .gnav_btn--lines span {
  @include mq(med) {
    &:nth-of-type(1) {
      transform: translateY(sprem(7)) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(sprem(-7)) rotate(45deg);
    }
  }
}
.tel_btn {
  @include mq(med) {
    right: sprem(64);
  }
  a {
    @include mq(med) {
      display: flex !important;
      width: 100%;
      height: 100%;
    }
  }
  svg {
    @include mq(med) {
      @include sprect(24);
      fill: var(--clr-primary-default);
    }
  }
}
.gnav--cta {
  text-align: center;
}
.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(8);
  }
}
.gnav--tel a {
  display: inline-flex !important;
  flex-direction: column;
}
.gnav--tel .tel {
  font-size: calc-fz(20);
}
.gnav--tel .num {
  font-size: calc-fz(24);
}
.gnav--tel .hours {
  font-size: calc-fz(14);
}
.gnav--contact a {
  padding: rem(24);
  @include mq(med, min, ps) {
    border-radius: 0;
  }
  @include mq(med) {
    padding: sprem(10) sprem(16);
    margin-left: auto;
    margin-right: auto;
  }
}

/* header 01
********************************************** */
.header-01 {
  @include mq(med, min, ps) {
    box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    .gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
    .gnav--menu > li.current-menu-item > .gnav--link {
      color: var(--clr-primary-default);
      background-color: var(--clr-bg-01);
    }
  }
  .head_sub_nav--wrap {
    @include mq(med) {
      padding-inline: sprem(14);
      background-color: var(--clr-wht);
    }
  }
  .head_sub_nav li {
    @include mq(med, min, ps) {
      margin-block: rem(8);
    }
  }
  .head_sub_nav a {
    line-height: 1.5;
    @include mq(med, min, ps) {
      box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    }
  }
}

/* header 02
********************************************** */
.header-02 {
  @include mq(med, min, ps) {
    border-top: rem(16) solid var(--clr-primary-default);
    box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    .gnav--menu > li:hover > .gnav--link,
    .gnav--menu > li.current-menu-item > .gnav--link,
    .menu-item-has-children:has(li.current-menu-item) > .gnav--link {
      color: var(--clr-primary-default);
      background-color: var(--clr-bg-01);
    }
  }
  .head_sub_nav--wrap {
    left: 50%;
    translate: -50% 0;
    min-width: rem(288);
  }
  .head_sub_nav {
    position: relative;
    min-width: rem(248);
    background-color: var(--clr-wht);
    @include mq(med, min, ps) {
      padding: rem(20);
      margin-top: rem(20);
      border-radius: rem(8);
      box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    }
    @include mq(med) {
      padding: 0;
    }
    @include mq(med, min, ps) {
      &::before {
        content: "";
        position: absolute;
        top: rem(-12);
        left: calc(50% - rem(24) / 2);
        width: rem(24);
        height: rem(12);
        background-color: var(--clr-wht);
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
      }
    }
  }
  .head_sub_nav a {
    position: relative;
    padding: rem(10);
    text-align: left;
    line-height: 1.5;
    @include mq(med) {
      padding: sprem(16) sprem(24) sprem(16) sprem(64);
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
    }
    &:where(:not([target="_blank"]))::before {
      right: rem(4);
      @include rect(8);
      border-top: 2px solid var(--clr-text-primary);
      border-right: 2px solid var(--clr-text-primary);
      transform: rotate(45deg);
      transition: var(--transit-default);
    }
    &[target="_blank"]::before {
      @include rect(16);
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/ico-tab.svg);
      background-size: cover;
      @include mq(med) {
        right: sprem(20);
        border: none;
        transform: inherit;
      }
    }
  }
  .gnav--cta-btn {
    position: relative;
    width: vw(160);
    min-width: 110px;
    @include mq(med) {
      width: 100% !important;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: rem(16);
      width: 100%;
      padding: vw(30) rem(8) vw(40);
      color: var(--clr-wht);
      line-height: 1.5;
      border-radius: 0 0 50vw 50vw;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);
      @include mq(med, min, ps) {
        position: absolute;
        top: 0;
        left: 0;
        flex-direction: column;
        height: calc(100% + rem(24));
      }
      @include mq(med) {
        justify-content: center;
        border-radius: 50vw;
      }
    }
    span {
      @include center-flex;
      height: rem(48);
    }
  }
  .gnav--contact a {
    background-color: var(--clr-primary-default);
    @include mq(med, min, ps) {
      margin-top: rem(-16);
      height: calc(100% + rem(24) + rem(16));
    }
    &:hover {
      background-color: var(--clr-primary-light);
    }
  }
  .gnav--line a {
    background-color: var(--clr-grn);
    &:hover {
      background-color: var(--clr-grn-light);
    }
  }
  .gnav--support a {
    background-color: var(--clr-org);
    &:hover {
      background-color: var(--clr-org-light);
    }
  }
}
