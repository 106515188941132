@use "../abstracts" as *;

/* cta_box
**************************************** */
.cta_box {
  display: flex;
  justify-content: center;
  gap: rem(24);
  @include mq(sp) {
    flex-direction: column;
    align-items: center;
    gap: sprem(10);
  }
}
.cta_box a {
  height: rem(80);
  @include mq(sp, min) {
    width: rem(320);
  }
}
@include mq(sp) {
  .cta_box li {
    width: 100%;
  }
}

/* cta_tel
**************************************** */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: rem(20) rem(12);
  line-height: var(--line-height-hdr);
  border-radius: 50vw;
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
  @include mq(sp) {
    height: sprem(64);
  }
}
.cta_tel a > span {
  display: block;
}
.cta_tel .tel {
  font-size: calc-fz(20);
}
.cta_tel .num {
  font-size: rem(28);
}
.cta_tel .hours {
  font-size: calc-fz(13);
}

/* cta_contact
**************************************** */
.cta_contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(10);
  @include p-tb(rem(20));
  border-radius: 50vw;
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
  @include mq(sp) {
    height: sprem(64);
  }
  &:hover {
    background-color: var(--clr-primary-light);
  }
}
.cta_contact .micro_copy {
  position: absolute;
  top: calc(-1em - rem(10));
  inset-inline: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: rem(13);
  color: var(--clr-primary-default);
  text-align: center;
  line-height: 1;
  &::before,
  &::after {
    content: '';
    width: rem(2);
    height: rem(16);
    background-color: var(--clr-secondary-default);
  }
  &::before{
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
}
