@use '../abstracts' as *;

/* ##############################################################################

  FORMY

############################################################################## */
#formy_form table { width: 100%; }
#formy_form th,
#formy_form td {
  padding: rem(16);
  vertical-align: middle;
  border-bottom: solid 1px #eee;
  @include mq(sp) {
    display: block;
    width: auto !important;
    @include p-lr(0);
  }
}
#formy_form th {
  font-weight: 500;
  white-space: nowrap;
  text-align: left;
  @include mq(sp, min, ps) {
    width: 34%;
  }
  @include mq(sp) {
    width: 100%;
    border-bottom:none;
    padding-bottom: 0;
    white-space: normal;
    font-weight: bold;
  }
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form table textarea {
  width: 100%;
  border-radius: rem(3);
  vertical-align: bottom;
}
#formy_form table input[type="text"],
#formy_form table input[type="email"],
#formy_form table input[type="tel"],
#formy_form table input[type="date"],
#formy_form table input[type="password"],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: rem(5) rem(15);
  border: 1px solid #ccc;
  font-family: YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif !important;
  font-size: calc-fz(16);
  transition: var(--transit-default);
}
#formy_form textarea { height: rem(100); }
#formy_form select { height: rem(40); }
#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#formy_form input:hover { opacity: .7; }
#formy_form textarea:hover { opacity: .7; }
#formy_form input:focus { outline: none; }
#formy_form .parsley-validated { background-color: #eee; }
#formy_form .parsley-error { background-color: #fee; }
#formy_form .parsley-success { background-color: var(--clr-wht); }
#formy_form .help_text {
  font-size: calc-fz(14);
  color: #999;
}
#formy_form .hidden_help { display: none; }
.formy_privacy div {
  overflow-y: scroll;
  height: rem(140);
  border: solid 1px #ccc;
  font-size: calc-fz(14);
  padding: rem(8) rem(16);
}
#formy_form .requiredIcon {
  background-color: #f55;
  color: var(--clr-wht);
  margin: 0 0 0 1em;
  font-size: calc-fz(12);
  padding: rem(2) rem(5);
  border-radius: rem(3);
  float: right;
}
#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: rem(32);
  text-align: center;
}
#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: var(--clr-wht);
  border-radius: rem(3);
  padding: rem(16) rem(32);
  -webkit-appearance: none;
  appearance: none;
  font-family: var(--font-primary);
  transition: var(--transit-default);
  &:not(:first-child) {
    margin-bottom: rem(24);
  }
  @include mq(sp) {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}
#formy_form ul li input[type="radio"],
#formy_form ul li input[type="checkbox"] { display: none !important; }
#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: rem(8) rem(8) rem(8) rem(40);
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
  transition: var(--transit-default);
}
#formy_form ul li label:hover { opacity: .7; }
#formy_form ul li label::before {
  content: "";
  position: absolute;
  top: rem(12);
  left: rem(16);
  @include rect(16);
  border: 2px solid #788b93;
}
#formy_form ul li input[type="radio"] + label::before { border-radius: rem(10); }
#formy_form ul li input[type="radio"]:checked + label,
#formy_form ul li input[type="checkbox"]:checked + label {
  color: #e75f5f;
  font-weight: bold;
}
#formy_form ul li input[type="radio"]:checked + label::before,
#formy_form ul li input[type="checkbox"]:checked + label::before {
  border-color: #e75f5f;
}
#formy_form ul li input[type="radio"]:checked + label::after,
#formy_form ul li input[type="checkbox"]:checked + label::after {
  content: "";
  width: rem(10);
  height: rem(18);
  top: rem(4);
  left: rem(20);
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}
.formy_confirm { background-color: #4dbaff; }
.formy_submit_disabled { background-color: #ccc; }
#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}
#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: rem(8) rem(27);
  min-width: auto;
  font-size: calc-fz(15);
  border: 1px solid #aaa;
  border-radius: rem(32);
}
.formy_send { background-color: #ff6600; }
#formy_form #total_required {
  padding: rem(16);
  color: #f55555;
  text-align: center;
}

@include mq(sp, min, ps) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@include mq(sp) {
  #formy_form td { padding-top: 0; }
  #formy_form .autoConfirmBack { margin-bottom: sprem(8); }
}