@use '../abstracts' as *;

/* ##############################################################################

  FORM

############################################################################## */
.nf-form-cont .nf-form-content label,
.nf-form-cont .nf-field-label .nf-label-span {
  font-size: calc-fz(18);
  @include mq(sp) {
    font-size: sprem(15);
    margin-bottom: 6px!important;
  }
}
.nf-form-cont .nf-field-label label,
.nf-form-cont .nf-form-content .nf-label-span {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}
.nf-form-cont .nf-form-fields-required {
  display: none !important;
}
.nf-form-cont .nf-field-element {
  font-family: YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif !important;
}
#ninja_forms_required_items,
.nf-form-cont .nf-field-container {
  margin-bottom: rem(24);
  @include mq(sp) {
    margin-bottom: sprem(16);
  }
}
.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element>div,
.nf-form-cont .nf-form-content input:not([type=button]),
.nf-form-cont .nf-form-content textarea {
  background: var(--clr-wht);
  color: var(--clr-text-primary);
  border-radius: rem(3);
  vertical-align: bottom;
  font-family: YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif !important;
  font-size: calc-fz(16);
  transition: var(--transit-default, all .4s ease-out);
  font-weight: 500;
}
.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element>div:focus,
.nf-form-cont .nf-form-content input:not([type=button]):focus,
.nf-form-cont .nf-form-content textarea:focus {
  color: var(--clr-text-primary);
}
.nf-form-content .list-checkbox-wrap .nf-field-element li label,
.nf-form-content .list-image-wrap .nf-field-element li label,
.nf-form-content .list-radio-wrap .nf-field-element li label {
  cursor: pointer;
}
.nf-form-content input[type=checkbox]:focus+label:after,
.nf-form-content input[type=radio]:focus+label:after {
  box-shadow: none;
}
.checkbox-wrap .nf-field-element label.nf-checked-label:before,
.checkbox-wrap .nf-field-label label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-element label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-label label.nf-checked-label:before {
  color: var(--clr-primary-default);
}
.nf-form-cont .ninja-forms-req-symbol {
  font-size: 0 !important;
  &::before {
    content: "\5FC5\9808"; /* 必須 */
    background-color: #f55;
    color: var(--clr-wht);
    margin-left: .5em;
    font-size: calc-fz(12);
    padding: rem(2) rem(5);
    border-radius: rem(3);
    font-family: YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif !important;
  }
}
.nf-form-cont .nf-form-content .submit-wrap {
  text-align: center;
}
.nf-form-cont .nf-form-content .submit-wrap input:not([type=button]) {
  max-width: 100%;
  @include rect(320,64);
  font-size: calc-fz(18);
  line-height: 1;
  letter-spacing: .1em;
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
  border-radius: rem(50);
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  transition: var(--transit-default, all .4s ease-out);
  @include mq(sp, min) {
    margin-top: sprem(24);
  }
}
.nf-form-cont .nf-form-errors {
  margin-top: -1em;
  text-align: center;
}

@include mq(sp, min, ps) {
}
@include mq(sp) {
}

/* リキャプチャ・キャプション
**************************************** */
.grecaptcha-badge { visibility: hidden; }
.recaptcha_policy {
  padding: 0;
  margin-top: rem(48);
  color: var(--clr-text-primary);
  font-size: calc-fz(10);
  text-align: center;
  letter-spacing: 0;
  @include mq(sp) {
    text-align: left;
  }
}
.recaptcha_policy a {
  color: #69ABDB;
  font-size: calc-fz(12);
  text-decoration: underline;
}

/* ##############################################################################

  CF7

############################################################################## */
#cf7_form {
  width: 100%;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}
#cf7_form dl:not(:last-child) {
  margin-bottom: 1.5rem;
}
#cf7_form dt,
#cf7_form dd {
  @include mq(sp) {
    display: block;
    width: auto !important;
    @include p-lr(0);
  }
}
#cf7_form dt {
  margin-bottom: rem(10);
  font-size: calc-fz(18);
  font-weight: 700;
  @include mq(sp) {
    font-size: sprem(15);
  }
}
#cf7_form input:not([type*="radio"]),
#cf7_form select,
#cf7_form textarea {
  width: 100%;
  margin: 0;
  padding: rem(5) rem(15);
  border: 1px solid #ccc;
  font-family: YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif !important;
  font-size: calc-fz(16);
  vertical-align: bottom;
  border-radius: rem(3);
  transition: var(--transit-default);
}
#cf7_form input:not([type*="radio"]) { height: 50px; }
#cf7_form textarea {
  height: 100px;
  @include p-tb(rem(12));
}
#cf7_form select {
  height: 40px;
  cursor: pointer;
}
#cf7_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#cf7_form input:hover { opacity: .7; }
#cf7_form textarea:hover { opacity: .7; }
#cf7_form input:focus { outline: none; }
#cf7_form .parsley-validated { background-color: #eee; }
#cf7_form .parsley-error { background-color: #fee; }
#cf7_form .parsley-success { background-color: var(--clr-wht); }
#cf7_form .help_text {
  font-size: calc-fz(14);
  color: #999;
}
#cf7_form .wpcf7-radio {
  display: flex;
  align-items: center;
  gap: rem(32);
}
#cf7_form .wpcf7-list-item {
  margin: 0;
  label {
    display: flex;
    align-items: center;
    gap: rem(8);
    cursor: pointer;
  }
  input {
    width: rem(24);
    margin: 0;
    scale: 1.2;
  }
  .wpcf7-list-item-label {
    font-weight: 700;
  }
}
#cf7_form .hidden_help { display: none; }
.formy_privacy div {
  overflow-y: scroll;
  height: rem(140);
  border: solid 1px #ccc;
  font-size: calc-fz(14);
  padding: rem(8) rem(16);
}
#cf7_form .requiredIcon {
  background-color: #f55;
  color: var(--clr-wht);
  margin-left: 1em;
  font-size: calc-fz(12);
  padding: rem(2) rem(5);
  border-radius: rem(3);
}
#cf7_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: rem(16);
  text-align: center;
}
#cf7_btn input {
  max-width: 100%;
  @include rect(320,64);
  font-size: calc-fz(18);
  line-height: 1;
  letter-spacing: .1em;
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
  border: none;
  border-radius: rem(50);
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  transition: var(--transit-default, all .4s ease-out);
}
#cf7_btn .wpcf7-spinner {
  margin-bottom: rem(16);
}
#cf7_form ul li input[type="radio"],
#cf7_form ul li input[type="checkbox"] { display: none !important; }
#cf7_form ul li label {
  position: relative;
  display: inline-block;
  padding: rem(8) rem(8) rem(8) rem(40);
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
  transition: var(--transit-default);
}
#cf7_form ul li label:hover { opacity: .7; }
#cf7_form ul li label::before {
  content: "";
  position: absolute;
  top: rem(12);
  left: rem(16);
  @include rect(16);
  border: 2px solid #788b93;
}
#cf7_form ul li input[type="radio"] + label::before { border-radius: rem(10); }
#cf7_form ul li input[type="radio"]:checked + label,
#cf7_form ul li input[type="checkbox"]:checked + label {
  color: #e75f5f;
  font-weight: bold;
}
#cf7_form ul li input[type="radio"]:checked + label::before,
#cf7_form ul li input[type="checkbox"]:checked + label::before {
  border-color: #e75f5f;
}
#cf7_form ul li input[type="radio"]:checked + label::after,
#cf7_form ul li input[type="checkbox"]:checked + label::after {
  content: "";
  width: rem(10);
  height: rem(18);
  top: rem(4);
  left: rem(20);
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}
.formy_confirm { background-color: #4dbaff; }
.formy_submit_disabled { background-color: #ccc; }
#cf7_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}
#cf7_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: rem(8) rem(27);
  min-width: auto;
  font-size: calc-fz(15);
  border: 1px solid #aaa;
  border-radius: rem(32);
}
.formy_send { background-color: #ff6600; }
#cf7_form #total_required {
  padding: rem(16);
  color: #f55555;
  text-align: center;
}

@include mq(sp, min, ps) {
  #cf7_form .confirm-message {
    text-align: center;
  }
}
@include mq(sp) {
  #cf7_form dd { padding-top: 0; }
  #cf7_form .autoConfirmBack { margin-bottom: sprem(8); }
}