@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  height: rem(400);
  color: var(--clr-wht);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-ptn01.jpg);
  mask-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl-mask.svg);
  mask-size: 100% auto;
  mask-position: center bottom;
  background-color: var(--clr-primary-light);
  overflow: hidden;
  @include mq(sp) {
    height: sprem(196);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/ttl-mask_sp.svg);
    mask-size: cover;
    mask-position: center bottom;

  }
  & > .inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .breadcrumbs {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .breadcrumbs a {
    color: currentColor;
  }
}
.page_ttl--bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.page_ttl-jp {
  font-size: rem(48);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: calc-fz(16);
  @include mq(sp) {
    font-size: sprem(14);
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04,
.ttl-01-sub {
  span {
    letter-spacing: inherit;
  }
}
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: 0.1em;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 1em;
  }
}
.ttl-01 {
  font-size: rem(48);
  @include mq(sp) {
    font-size: sprem(28);
  }
  &.font-en {
    font-size: rem(72);
    color: var(--clr-primary-default);
  }
  & + .ttl-01-sub {
    margin-top: rem(-30);
    @include mq(sp) {
      margin-top: sprem(-16);
    }
  }
  &.clr-wht + .ttl-01-sub {
    color: var(--clr-wht);
    &::after {
      background-color: var(--clr-wht);
    }
  }
}

.ttl-01-sub {
  display: block;
  color: var(--clr-primary-default);
  font-size: calc-fz(16);
  letter-spacing: 0.1em;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &::after {
    content: "";
    display: block;
    width: rem(40);
    margin-top: rem(20);
    height: rem(2);
    border-radius: 50vw;
    background-color: var(--clr-primary-default);
  }
  &.txt-ctr::after {
    @include auto-margin;
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(48, 16, em);
  }
}
.ttl-02 {
  font-size: rem(40);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.ttl-03 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
.ttl-04 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(20);
  }
}
