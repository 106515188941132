@use "sass:math";
@use '../abstracts' as *;

// 単位は㎳（ミリ秒）
$aniMax: 4000; // 最大
$aniStep: 100; // ステップ
@for $i from 0 through ceil(math.div($aniMax, $aniStep)) {
  $val: $i * $aniStep;
  .ani-delay#{$val} {
    animation-delay: #{$val}ms;
  }
}