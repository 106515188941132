@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;
$wid-var: 1700; // フォントサイズは画面幅がこの値～$wid-pcで可変する
$header-hgt: 156;
$header-hgt-sp: 64;
$header-hgt-fx: 156;

// カラー管理
$colors: (
  clr-primary-default: #FC5A03,
  clr-primary-dark: #C64602,
  clr-primary-light: #FF7E39,

  /* ※CTA周りは、デフォルトは secondary を当てています。自由に変えてください */
  clr-secondary-default: #EB3535,
  clr-secondary-dark: #D03030,
  clr-secondary-light: #F25353,

  clr-bg-01: #FEF4E5,
  clr-bg-02: #FFFCF9,
  clr-bg-03: #FDE9CE,

  clr-sub-06: #6790B7,
  clr-sub-05: #A2689B,
  clr-sub-04: #CF727C,
  clr-sub-03: #F48400,
  clr-sub-02: #A8BF71,
  clr-sub-01: #51AAA6,

  clr-mono-06: #333841,
  clr-mono-05: #646B7A,
  clr-mono-04: #9599A3,
  clr-mono-03: #BABDC4,
  clr-mono-02: #DFDFE0,
  clr-mono-01: #F2F2F2,

  clr-text-primary: #404044,
  clr-text-secondary: #7A7A81,
  clr-text-placeholder: #CCC,
  clr-text-light: #F7F7F7,

  clr-wht: #fff,
  clr-blk: #000,
  clr-grn: #3CB902,
  clr-grn-light: #73cb4a,
  clr-yel: #FFEBA1,
  clr-org: #F59300,
  clr-org-light: #f9ae3e,
  clr-org2: #FFF7EA,
  clr-org3: #CF4800,
  clr-org4: #F95A03,
  clr-org5: #F97E39,
  clr-org6: #F55722,
  clr-org7: #EB7324,

);

// CSS変数
:root {
  // typography
  --ltr-space-default: 0.03em;
  --line-height-default: 2;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-primary: "新丸ゴ R", "新丸ゴ M", "新丸ゴ DB", sans-serif;
  --font-primary-300: "新丸ゴ L", sans-serif;
  --font-primary-500: "新丸ゴ M", sans-serif;
  --font-primary-600: "新丸ゴ DB", sans-serif;
  --font-secondary: "ヒラギノ角ゴ オールド W6 JIS2004", sans-serif;
  --font-jp: var(--font-primary);
  --font-en: "Raleway", sans-serif;

  //カラーを出力
  @each $key, $color in $colors {
    --#{$key} : #{$color};
  }

  // animation
  --transit-default: all .4s ease-out;
}

// SASS Maps

$z-index: (
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);
