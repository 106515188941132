@use "../abstracts" as *;

/* --- inner --- */
.inner {
  width: 90%;
  max-width: rem(1200);
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: 86%;
  }
}
.inner-sm {
  max-width: 720px;
}
.inner-lg {
  max-width: rem(1456);
}
.inner-xl {
  max-width: rem(1760);
}
.inner-900 {
  max-width: rem(900);
}
.inner-1020 {
  max-width: rem(1020);
}
.inner-1120 {
  max-width: rem(1120);
}
.inner-1360 {
  max-width: rem(1360);
}
.inner-1440 {
  max-width: rem(1440);
}
.inner-1512 {
  max-width: rem(1512);
}
.inner-1712 {
  max-width: rem(1712);
}
.inner-1800 {
  max-width: rem(1800);
}