@use "../abstracts" as *;

/* ##############################################################################

    FOOTER

############################################################################## */

/* section_cta
**************************************** */
.section_cta {
  --deco-color: var(--clr-bg-01);
  position: relative;
  @include p-tb(rem(56),rem(528));
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/bg-cta.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  background-color: var(--clr-bg-01);
  @include mq(sp) {
    @include p-tb(sprem(32),sprem(196));
  }
  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    translate: 0 -100%;
    width: 100%;
    height: vw(105);
    aspect-ratio: 1920/105;
    background-color: var(--deco-color);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/section-mask-01.svg);
    mask-size: 100% auto;
    mask-position: center top;
    mask-repeat: no-repeat;
  }
}
.section_cta--ttl {
  @include center-flex;
  flex-direction: column;
  gap: rem(16);
  color: var(--clr-primary-light);
  font-size: rem(88);
  line-height: 1;
  font-weight: 700;
  @include mq(sp) {
    font-size: sprem(48);
    margin-bottom: sprem(32);
  }
  .copy {
    @include center-flex;
    gap: rem(24);
    font-family: var(--font-primary-500);
    font-size: rem(18);
    &::before,
    &::after {
      content: '';
      height: rem(16);
      width: 2px;
      border: 1px solid currentColor;
      border-radius: 50vw;
      transform-origin: center;
    }
    &::before {
      rotate: -15deg;
    }
    &::after {
      rotate: 15deg;
    }
  }
}
.section_cta--link {
  @include center-flex;
  flex-direction: column;
  padding: rem(56) rem(40);
  color: var(--clr-wht);
  background-color: var(--section-cta-clr);
  border-radius: rem(30);
  box-shadow: 0px 5px 17px 0px rgba(253, 15, 0, 0.05);
  @include mq(sp) {
    padding: sprem(32) rem(24);
  }
  &.involved {
    --section-cta-clr: var(--clr-org);
  }
  &.line {
    --section-cta-clr: var(--clr-grn);
  }
}
.cta_content--ttl {
  @include center-flex;
  flex-direction: column;
  gap: rem(24);
  font-family: var(--font-primary-500);
  font-size: rem(32);
  line-height: 1.7;
  @include mq(sp) {
    font-size: sprem(26);
    gap: sprem(8);
    margin-bottom: sprem(16);
  }
  .dot {
    display: flex;
    justify-content: center;
    gap: rem(8);
    &::after,
    &::before {
      content: '';
    }
    span,
    &::after,
    &::before {
      display: block;
      @include rect(6);
      border-radius: 50vw;
      background-color: var(--clr-wht);
    }
  }
}
.section_cta--btn a {
  color: var(--section-cta-clr);
  border: 2px solid transparent;
  background-color: var(--clr-wht);
  &:hover {
    color: var(--clr-wht);
    border-color: var(--clr-wht);
    background-color: var(--section-cta-clr);
    &.arrow::after {
      background-color: var(--section-cta-clr);
    }
  }
}


/* footer
**************************************** */
.container:has(+ .section_cta) {
  padding-bottom: rem(105);
  @include mq(sp) {
    padding-bottom: sprem(80);
  }
}
.section_pdg:has(+ .section_cta),
.lps_sec:last-child {
  padding-bottom: calc(rem(105) + rem(80));
  @include mq(sp) {
    padding-bottom: sprem(80);
  }
}

.footer {
  position: relative;
  padding-top: rem(40);
  color: var(--clr-wht);
  background-color: var(--clr-primary-light);
  z-index: 10;
  &:not(:first-child)::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    translate: 0 -100%;
    width: 100%;
    height: vw(105);
    aspect-ratio: 1920/105;
    background-color: var(--clr-primary-light);
    mask-image: url(/wp/wp-content/themes/standard_sass/images/common/section-mask-01.svg);
    mask-size: 100% auto;
    mask-position: center top;
    mask-repeat: no-repeat;
  }
  a {
    color: var(--clr-wht);
  }
}
.footer--deco {
  position: absolute;
  left: 50%;
  top: rem(-28);
  translate: -50% -100%;
  z-index: 10;
  @include mq(sp) {
    top: 0;
    width: calc(spvw(513) * 0.4) !important;
  }
}


/* footer_top
********************************************** */
/* ---fnav --- */
.fnav--menu a {
  display: block;
  padding: 0.5em 1em;
  &:hover {
    opacity: 0.7;
  }
  @include mq(sp) {
    position: relative;
    padding: 0.5em;
    &:not([href="javascript:void(0);"])::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      @include sprect(6);
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
    }
  }
}
.fnav--menu .sub-menu {
  font-size: calc-fz(15);
  line-height: 1;
  opacity: 0.75;
}

/* ---sns_area --- */
.sns_area a {
  display: grid;
  place-items: center;
  @include rect(40);
  &:hover {
    opacity: 0.7;
  }
}
.sns_area svg {
  @include rect(24);
  fill: currentColor;
  &.icon-x {
    @include rect(22);
  }
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding: rem(8) 0;
}
.footer_btm:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.privacy {
  @include mq(sp) {
    text-align: center;
    margin-bottom: 0.5em;
  }
}
.privacy a:hover {
  opacity: 0.7;
}
.copyright {
  gap: 1em;
  justify-content: center;
  @include mq(sp) {
    gap: 0.5em;
  }
}
.pbl a,
.pbl img {
  display: block;
}
.pbl a {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

/* footer_support
**************************************** */
.footer_support {
  @include mq(sp) {
    margin-bottom: sprem(48);
  }
}
.footer_support--ttl {
  font-size: rem(24);
  line-height: 1.7;
  text-align: center;
}
.footer_support--item {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 2;
  gap: rem(8);
}
.footer_support--box {
  @include center-flex;
  padding: rem(12) rem(40);
  background-color: var(--clr-wht);
  border-radius: rem(10);
}
.footer_support--dial img {
  @include mq(sp) {
    width: calc(spvw(313) * 0.7) !important;
  }
}
.footer_support--tel a {
  @include center-flex;
  flex-direction: column;
  font-family: var(--font-primary-500);
  color: var(--clr-primary-default);
  line-height: 1.4;
  .text {
    font-size: rem(16);
  }
  .tel {
    display: flex;
    gap: rem(8);
    font-size: rem(32);
  }
  .teltime {
    padding: rem(6) rem(20);
    font-family: var(--font-primary-600);
    color: var(--clr-sub-03);
    line-height: 1.6;
    background-color: var(--clr-org2);
    border-radius: 50vw;
  }
}

/* ##############################################################################

    スタンダード用

############################################################################## */

/* footer-simple
*************************************************** */
.footer-simple {
  padding: rem(8) 0;
}
.footer-simple:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

// お問い合わせシンプル「以外」のとき
/* footer-01
  *************************************************** */
.footer-01 {
  .footer--logo {
    text-align: center;
  }
  .footer--address {
    font-family: var(--font-primary-500);
    color: var(--clr-primary-default);
    line-height: 1.7;
  }
  .footer--address a {
    color: var(--clr-primary-default);
  }

  .fnav {
    margin-bottom: rem(40);
    @include mq(sp) {
      margin-bottom: sprem(48);
    }
  }
  .footer--cta {
    margin-bottom: rem(48);
  }
  .footer--cta a {
    margin-inline: auto;
    text-align: center;
  }
  .fnav--menu {
    justify-content: center;
    line-height: 1;
    @include mq(sp, min, ps) {
      gap: 1em 0;
    }
  }
  .fnav--menu a {
    font-size: rem(18);
    padding: 1em 0;
    @include mq(sp, min, ps) {
      margin-block: -1em;
    }
    @include mq(sp) {
      @include p-tb(0.5em);
      font-size: sprem(14);
    }
  }
  .fnav--menu a[href="javascript:void(0);"] {
    pointer-events: none;
  }
  .fnav--menu .sub-menu {
    margin-top: rem(16);
    @include mq(sp) {
      margin-top: sprem(8);
    }
  }
  .fnav--menu .sub-menu a {
    position: relative;
    display: flex;
    gap: rem(8);
    font-size: rem(16);
    @include mq(sp) {
      font-size: sprem(13);
    }
    &[target="_blank"]::after {
      content: "";
      display: block;
      @include rect(16);
      background-image: url(/wp/wp-content/themes/standard_sass/images/common/ico-tab-wht.svg);
      background-size: cover;
    }
    @include mq(sp) {
      &[target="_blank"]::before {
        content: none;
      }
    }
  }

  .footer--bnr {
    margin-right: 0;
    margin-left: auto;
    a:hover {
      opacity: 0.7;
    }
  }
  .sns_area ul {
    justify-content: center;
  }
  .copyright {
    @include p-tb(rem(24));
    font-size: rem(14);
    line-height: 1;
    justify-content: center;
    @include mq(sp) {
      @include p-tb(sprem(12));
    }
  }
}

/* footer-02
  *************************************************** */
.footer-02 {
  .inner {
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "logo sns_area"
        "fnav copyright";
    }
  }
  .footer--logo {
    @include mq(sp, min, ps) {
      grid-area: logo;
      border-bottom: 1px solid var(--clr-mono-06);
    }
    @include mq(sp) {
      text-align: center;
    }
  }
  .fnav {
    font-size: calc-fz(14);
    @include mq(sp, min, ps) {
      grid-area: fnav;
    }
    @include mq(sp) {
      margin-block: sprem(24);
      padding-block: sprem(14);
      border-block: 1px solid var(--clr-mono-06);
    }
  }
  .fnav--menu {
    line-height: 1.5;
    @include mq(sp, min, ps) {
      margin-inline: -1em;
    }
  }
  .fnav--menu a {
    @include mq(sp) {
      margin-inline: -0.5em;
    }
  }
  .menu-item-has-children,
  .fnav--menu .sub-menu {
    @include mq(sp, min, ps) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .fnav--menu .sub-menu {
    @include mq(sp) {
      margin-left: 1em;
    }
  }
  .sns_area {
    @include mq(sp, min, ps) {
      grid-area: sns_area;
      border-bottom: 1px solid var(--clr-mono-06);
    }
  }
  .sns_area ul {
    justify-content: flex-end;
    @include mq(sp) {
      justify-content: center;
    }
  }
  .copyright {
    @include mq(sp, min, ps) {
      grid-area: copyright;
      justify-content: flex-end;
    }
    @include mq(sp) {
      justify-content: center;
      flex-direction: row;
      margin-top: sprem(14);
    }
  }
  .copyright small {
    font-size: calc-fz(12);
  }
  .fnav,
  .copyright {
    @include mq(sp, min, ps) {
      padding-top: rem(32);
    }
  }
  .footer--logo,
  .sns_area {
    @include mq(sp, min, ps) {
      padding-bottom: rem(32);
    }
  }
  .footer--cta,
  .footer--address {
    font-size: calc-fz(13);
    line-height: 1.5;
    margin-top: rem(24);
    @include mq(sp, min, ps) {
      padding-top: rem(24);
      border-top: 1px solid var(--clr-mono-06);
    }
  }
}

/* footer-03
  *************************************************** */
.footer-03 {
  .inner {
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "logo logo"
        "fnav cta"
        "copyright sns_area";
    }
  }
  .footer--logo {
    margin-bottom: rem(24);
    @include mq(sp, min, ps) {
      grid-area: logo;
    }
    @include mq(sp) {
      text-align: center;
    }
  }
  .footer--cta {
    display: flex;
    margin-bottom: rem(24);
    @include mq(sp, min, ps) {
      grid-area: cta;
    }
    @include mq(sp) {
      align-items: center;
      justify-content: center;
    }
  }
  .footer--cta .cta_tel a {
    margin-inline: auto;
  }
  .fnav {
    margin-bottom: rem(24);
    @include mq(sp, min, ps) {
      grid-area: fnav;
    }
  }
  .fnav--menu {
    @include mq(sp, min, ps) {
      margin-inline: -1em;
      gap: rem(40);
    }
  }
  .cta {
    @include mq(sp, min, ps) {
      grid-area: cta;
    }
  }
  .copyright {
    @include mq(sp, min, ps) {
      grid-area: copyright;
    }
  }
  .sns_area {
    @include mq(sp, min, ps) {
      grid-area: sns_area;
    }
    @include mq(sp) {
      margin-bottom: sprem(32);
      margin-top: sprem(14);
    }
  }
  .sns_area ul {
    justify-content: flex-end;
    @include mq(sp) {
      justify-content: center;
    }
  }
  .copyright {
    justify-content: flex-start;
  }
  .copyright,
  .sns_area {
    @include mq(sp, min, ps) {
      padding-top: rem(24);
      border-top: 1px solid var(--clr-mono-06);
    }
  }
  .footer--address {
    font-size: calc-fz(13);
    line-height: 1.5;
    opacity: 0.5;
    @include mq(sp) {
      margin-bottom: sprem(14);
    }
  }
}

/* footer-04
  *************************************************** */
.footer-04 {
  .inner {
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: rem(640) 1fr;
      gap: 0 rem(150);
      grid-template-areas:
        "img logo"
        "img address"
        "img sns_area"
        "img cta"
        "img copyright";
    }
    &::before {
      content: "::before";
      display: block;
      width: 100%;
      background-color: #aaa;
      @include mq(sp, min, ps) {
        grid-area: img;
      }
      @include mq(sp) {
        width: 100vw;
        height: sprem(180);
        margin-inline: calc(50% - 50vw);
        margin-bottom: sprem(28);
      }
    }
  }
  .footer--logo {
    margin-bottom: rem(32);
    @include mq(sp, min, ps) {
      grid-area: logo;
    }
    @include mq(sp) {
      text-align: center;
    }
  }
  .footer--address {
    margin-bottom: rem(16);
    font-size: calc-fz(15);
    @include mq(sp, min, ps) {
      grid-area: address;
    }
  }
  .sns_area {
    margin-bottom: rem(32);
    @include mq(sp, min, ps) {
      grid-area: sns_area;
    }
    @include mq(sp) {
      margin-top: sprem(14);
    }
  }
  .sns_area ul {
    @include mq(sp) {
      justify-content: center;
    }
  }
  .footer--cta {
    margin-bottom: rem(32);
    @include mq(sp, min, ps) {
      grid-area: cta;
    }
  }
  .copyright {
    @include mq(sp, min, ps) {
      grid-area: copyright;
    }
  }
  .fnav {
    grid-column: 1 / -1;
    margin-top: rem(32);
    margin-inline: -1em;
    font-size: calc-fz(14);
    line-height: 1.5;
  }
}
